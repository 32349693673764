import styled from '@emotion/styled'
import { Accordion } from 'app/components/Common/Accordion'
import { Image } from 'app/components/Common/Image'
import React, { memo, useState } from 'react'

export interface Props {
  title?: string
  subtitle?: string
  elements: any[]
}

export const BlockFAQ = memo(function BlockFAQ({
  title,
  subtitle,
  elements,
}: Props) {
  const [activeCategory, setActiveCategory] = useState(0)

  return (
    <Container>
      <Wrapper>
        <Top>
          {title ? <Heading>{title}</Heading> : null}
          {subtitle ? <Title>{subtitle}</Title> : null}
        </Top>
        <CategoryList>
          {elements.length > 1
            ? elements.map((element, index) => (
                <Category
                  className={activeCategory == index ? 'active' : undefined}
                  onClick={(e) => {
                    setActiveCategory(index)
                  }}
                  key={index}
                >
                  {element.icon ? (
                    <CategoryIcon {...element.icon} />
                  ) : (
                    <CategoryIconSVG>
                      <img src={element.iconSVG} />
                    </CategoryIconSVG>
                  )}

                  <CategoryTitle>{element.title}</CategoryTitle>
                </Category>
              ))
            : null}
        </CategoryList>
        <AccordionsList>
          {elements.length > 1
            ? elements.map((element, index) =>
                element.items?.length > 1 ? (
                  <AccordionWrapper
                    key={index}
                    className={activeCategory == index ? 'active' : undefined}
                  >
                    <Accordion items={element.items} />
                  </AccordionWrapper>
                ) : null,
              )
            : null}
        </AccordionsList>
      </Wrapper>
    </Container>
  )
})

const Container = styled.section`
  position: relative;
  width: 100%;
  margin: 10vw 0 6vw 0;
  padding: 0 10vw;

  @media (max-width: 991px) {
    margin: 60px 0;
    margin-top: 120px;
    padding: 0 30px;
  }
`

const Wrapper = styled.div`
  position: relative;
  width: 100%;
`

const Top = styled.div`
  max-width: 41.875rem;
  margin: 0 auto;

  @media (max-width: 1199px) {
    max-width: 21rem;
  }
`

const Heading = styled.h1`
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 2.5vw;
  text-align: center;
  text-transform: uppercase;

  @media (max-width: 991px) {
    font-size: 28px;
  }
`

const Title = styled.h2`
  margin-top: 1.125rem;
  color: ${({ theme }) => theme.colors.variants.neutralDark3};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 19px;
  text-align: center;

  @media (max-width: 991px) {
    margin-top: 20px;
  }
`

const CategoryList = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  margin: 3vw 0;

  @media (max-width: 991px) {
    margin: 20px 0;
  }
`
const Category = styled.div`
  flex: 1;
  padding: 3vw 0;
  cursor: pointer;
  font-size: 0.9vw;
  letter-spacing: 2px;
  background-color: ${({ theme }) => theme.colors.variants.neutralLight1};
  text-align: center;
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.subheading};
  text-transform: uppercase;
  position: relative;

  &::before {
    width: 0;
    height: 0;
    content: ' ';
    display: none;
    border-style: solid;
    border-width: 0.9vw 0.7vw 0 0.7vw;
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translate3d(-50%, 0, 0);
    border-color: ${({ theme }) => theme.colors.variants.neutralLight3}
      transparent transparent transparent;
  }

  &.active::before {
    display: block;
  }

  &::after {
    top: 50%;
    right: 0;
    height: 20%;
    width: 1px;
    content: ' ';
    position: absolute;
    background: ${({ theme }) => theme.colors.variants.neutralLight3};
    transform: translate3d(0, -50%, 0);
  }

  &.active {
    background: ${({ theme }) => theme.colors.variants.neutralLight3};
  }

  @media (max-width: 1439px) {
    font-size: 14px;
  }

  @media (max-width: 991px) {
    padding: 20px 0;
  }
`

const CategoryTitle = styled.div`
  @media (max-width: 991px) {
    display: none;
  }
`

const CategoryIcon = styled(Image)`
  display: block;
  img {
    height: 0.7vw;
    width: auto;
    margin-bottom: 10px;
  }

  @media (max-width: 991px) {
    img {
      height: 18px;
      margin: 0;
    }
  }
`
const CategoryIconSVG = styled.div`
  display: block;
  img {
    height: 0.7vw;
    width: auto;
    margin-bottom: 10px;
  }

  @media (max-width: 991px) {
    img {
      height: 18px;
      margin: 0;
    }
  }
`

const AccordionsList = styled.div`
  position: relative;
  width: 100%;
`
const AccordionWrapper = styled.div`
  display: none;
  width: 100%;

  &.active {
    display: block;
  }
`
